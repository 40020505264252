<template>
  <v-container fluid class="fill-height">
    <v-row>
      <div class="middel_auto_with">
        <v-card outlined :loading="loading">
          <div class="">
            <div class="login_top_sec">
              <router-link to="/">   <img src="../../assets/images/login-logo.svg" alt="Exsport"/></router-link>
         
             <h2>Log in to your account</h2>
            <v-card-subtitle>Please login to your account to continue</v-card-subtitle>
            </div>
            <v-card-text>
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <form
                  @submit.prevent="handleSubmit(signin)"
                  @reset.prevent="reset"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Email / Mobile number"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="email"
                      :error-messages="errors"
                      label="Email / Mobile number"
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Password"
                    rules="required"
                    
                  >
                    <p class="ma-0 text-right">
                      <v-btn
                        text
                        small
                        class="pl-0 text-capitalize"
                        color="primary"
                        href="forgot-password"
                        >Forgot Password?</v-btn
                      >
                    </p>
                    <v-text-field
                      v-model="password"
                      :type="show1 ? 'text' : 'password'"
                      :error-messages="errors"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show1 = !show1"
                      dense
                      label="Password"
                      outlined
                    ></v-text-field>

                 
                  </ValidationProvider>
                  <div class="mt-3 d-flex justify-space-between">
                  <v-btn
          color="block"
          dark
    
            @click="$router.push('/')"
        >
         CANCEL
        </v-btn>
                    
                    <v-btn
                      type="submit"
                      class="primary"
                      :loading="loading"
                      depressed
                      >Sign in</v-btn
                    >
                  </div>
                  <div class="dont_have_on_account">
                    <h4>Don't have an account? <router-link to="signup">SIGN UP</router-link></h4>
                  </div>
                </form>
              </ValidationObserver>
            </v-card-text>
          </div>
        </v-card>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SignIn',
  data: () => ({
    email: '',
    password: '',
    loading: false,
    show1:false
  }),
  methods: {
    async signin() {
      if (this.loading) return
      this.loading = true

      const data = await this.$store
        .dispatch('signIn', { email: this.email, password: this.password })
              .catch(errors => {
                console.log(errors)
                this.$toasted.show('Invalid Email or Password', {
                  theme: "bubble",
                  position: "bottom-right",
                  errors: "errors",
                  singleton: true,
                  duration: 5000
                });
              })


      if (!data) return
      const user = await this.$store
        .dispatch('getCurrentUser', data.token)
        .catch((err) => console.log(err))

      if (!user) return

      this.loading = false
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style>
.v-application--is-ltr .v-text-field--outlined fieldset {
    background: #ffffff; border:1px solid #ebebeb
}

</style>
