<template>
  <v-container fluid class="fill-height">
    <v-row>
      <div class="middel_auto_with">
        <v-card outlined :loading="loading">
          <div class="">
            <div class="login_top_sec">
              <router-link to="/">   <img src="../../assets/images/login-logo.svg" alt="Exsport"/></router-link>
         
             <h2>FORGOT PASSWORD</h2>
            </div>
            <v-card-text>
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <form
                  @submit.prevent="handleSubmit(forgotpassword)"
                  @reset.prevent="reset"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Email / Mobile number"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="email"
                      :error-messages="errors"
                      label="Email"
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                 
                  <div class="mt-3 d-flex justify-space-between">
                  <v-btn
          color="block"
          dark
            @click="$router.push('/')"
        >
         CANCEL
        </v-btn>
                    
                    <v-btn
                      type="submit"
                      class="primary"
                      :loading="loading"
                      depressed
                      >Send</v-btn
                    >
                  </div>
                 
                </form>
              </ValidationObserver>
            </v-card-text>
          </div>
        </v-card>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ForgotPassword',
  data: () => ({
    email: '',
    password: '',
    loading: false
    
  }),
  methods: {
 

    async forgotpassword() {
      if (this.loading) return
      this.loading = true
  await this.$store
        .dispatch('forgotPassword', { email: this.email}).then((response) => {
            console.log(response)
                     if (response.status === 200) {
                               this.$refs.form.reset()
                             this.email='',
                             this.loading = false
              this.$toasted.show("Your New password Sent Your mail", {
                theme: "bubble",
                position: "bottom-right",
                type: "success",
                duration: 5000,
              });
            this.$router.push({ name: 'Home' })

                     }
                })
      
                 .catch(errors => {
                this.$toasted.show(errors, {
                  theme: "bubble",
                  position: "bottom-right",
                  errors: "errors",
                  singleton: true,
                  duration: 5000
                });
              })


        
  },



  }
}
</script>

<style>
.v-application--is-ltr .v-text-field--outlined fieldset {
    background: #ffffff; border:1px solid #ebebeb
}

</style>
